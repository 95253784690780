.homeTimeLimit {
    background: #F6F6F6;

    .homeTimeLimitBanner {
        height: 350px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .homeTimeLimitGoodsList {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        padding: 20px 0 40px;
        margin: 20px auto;
        width: 1400px;
        margin-left: 20px;

        .homeTimeLimitGoodsItem {
            cursor: pointer;
            padding: 20px;
            margin-bottom: 20px;
            width: 334px;
            height: 444px;
            background: #FFFFFF;
            box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.04);
            margin-left: 20px;
            &:hover {
                box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
                transform: translate3d(0, -2px, 0);
            }

            .homeTimeLimitGoodsItemImg {
                margin-bottom: 10px;
                width: 294px;
                height: 294px;
                background: #FFFFFF;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .homeTimeLimitGoodsName {
                margin-bottom: 10px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;

            }

            .progress {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .homeTimeLimitGoodsProgressW {
                    width: 198px;
                    height: 6px;
                    background: #FE8A4C;
                    border-radius: 3px;
                    opacity: 0.3;

                    .homeTimeLimitGoodsProgress {
                        width: 128px;
                        height: 6px;
                        background: red;
                        border-radius: 3px;
                    }
                }

                .yiQiang {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9A9A9A;
                }
            }

            .countDown {
                .countDownTime {
                    margin: 0 5px;
                    display: inline-block;
                    text-align: center;
                    line-height: 18px;
                    width: 18px;
                    height: 18px;
                    background: #F16100;
                    border-radius: 4px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
                .countDownTimeEnd{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #F16100;
                    
                }
            }


            .homeTimeLimitGoodsItemPrice {
                margin-bottom: 10px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #F16100;

                .discountedPrice {
                    display: flex;
                    align-items: center;

                    .discountedPriceNum {
                        margin-right: 10px;
                        font-size: 24px;
                    }

                    .xianshiBg {
                        margin-right: 10px;
                        display: inline-block;
                        text-align: center;
                        width: 50px;
                        height: 20px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                    }

                    .discountPriceNum {
                        text-decoration: line-through;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #9A9A9A;
                    }
                }

            }
        }
    }

    .ant-pagination {
        text-align: center;
        margin-bottom: 40px;
        padding-bottom: 40px;
    }
}