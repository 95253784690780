.footerBottomW {
    margin-top: 20px;
    height: 150px;
    background: #FFFFFF;

    .footerBottom {
        margin: auto;
        text-align: center;
        width: 1400px;
        height: 150px;
        background: #FFFFFF;

        .content {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9A9A9A;
        }
    }
}