.payment {
  width: 1400px;
  margin: auto;

  .top {
    display: flex;
    width: 1400px;
    height: 140px;
    background: rgba(22, 73, 154, 0.05);
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 43px;

    .left {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    .right {
      font-size: 44px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #F16100;

      span {
        color: #333333;
        font-size: 14px;
      }
    }
  }

  .QRCode {
    text-align: center;
    .price {
      font-size: 44px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #F16100;
      margin-bottom: 10px;
    }

    .QRCodeImg {
      margin-bottom: 20px;

      img {
        width: 280px;
        height: 280px;
      }
    }

    .tips {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9A9A9A;
      p{
        margin-bottom: 0px;
      }
    }
  }
}

.QRCodeModal {
  .ant-modal-content {
    width: 370px;
    margin: auto;

    .ant-modal-header {
      background-color: #f5f5f5;
      font-size: 18px;
      font-weight: 400;
      color: #424242;
      vertical-align: middle;
    }

    .ant-modal-body {
      padding-bottom: 6px;
      text-align: center;

      .wx_msgDiv {
        margin-top: 16px;

        .wx_msgSpan {
          color: #ff6700;
          cursor: pointer;
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-evenly;
      border: none;
    }
  }
}