.retailPurchases {
    width: 1166px;

    .retailPurchasesTop {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 75px;
        border-bottom: 1px solid #DFDFDF;
        background: #FFFFFF;

        .retailPurchasesTop_left {
            display: flex;
            align-items: center;
            .stockPrice {
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                letter-spacing: 1px;

                .price {
                    margin-right: 70px;
                    color: #F16100;
                }
            }

            .stockNumber {
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 22px;
                letter-spacing: 1px;
            }
        }

        .ant-space {
            display: flex;
            flex-direction: row;
            align-items: center;

            .ant-space-item {
                &>span {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }

                .ant-picker {
                    width: 250px;
                    height: 35px;
                    background: #FFFFFF;
                }
            }
        }

        .orderNumber {
            .name {
                margin-right: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }

            input {
                width: 250px;
                height: 35px;
                background: #FFFFFF;
            }
        }
    }

    .retailPurchasesContent {
        width: 1166px;
        background: #FFFFFF;

    }

    .retailPurchasesContentTable {
        .table {
            border-collapse: collapse;
            width: 1126px;
            margin: auto;

            .tableThead {
                .theadTr {
                    height: 60px;

                    th {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #9A9A9A;
                        line-height: 20px;
                        letter-spacing: 1px;
                    }
                }
            }

            .tableTbody {
                width: 1126px;

                .tbodyTr {
                    cursor: pointer;
                    border-collapse: collapse;
                    margin-bottom: 10px;
                    width: 1126px;
                    height: 110px;
                    background: #F6F6F6;
                    border: 1px solid #DFDFDF;

                    td {
                        text-align: center;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;

                        img {
                            width: 50px;
                            height: 50px;
                        }
                    }

                    &:hover {
                        height: 110px;
                        background: rgba(22, 73, 154, 0.05);
                        border: 1px solid #16499A;
                    }
                }

                .gap {
                    height: 10px;
                }
            }
        }
    }

    .retailPurchasesTopFooter {
        padding-top: 22px;
        text-align: center;
        height: 73px;
        background: #FFFFFF;
    }

}