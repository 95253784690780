.bannerContentW {
    padding-top: 20px;
    padding-bottom: 20px;
    background: #F6F6F6;

    .bannerContent {
        display: flex;
        justify-content: space-between;
        margin: auto;
        width: 1400px;
        height: 600px;

        .bannerContentLeft_menu {
            width: 250px;
            height: 600px;
            background: #FFFFFF;

            .fill {
                width: 250px;
                height: 5px;
                background: #16499A;

            }

            img {
                display: block;
                width: 250px;
                height: 500px
            }

            .recommend {
                padding: 10px 35px;
                width: 250px;
                height: 120px;
                cursor: pointer;

                .title {
                    height: 22px;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #16499A;
                    line-height: 22px;
                }

                .recommend_text {
                    height: 68px;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    align-content: space-around;

                    div {
                        display: block;
                        width: 70px;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #9A9A9A;
                        line-height: 20px;
                        cursor: pointer;
                    }
                }
            }
        }

        .bannerContentMiddle_carousel {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 860px;
        }

        .bannerContentMiddleBottom {
            display: flex;
            justify-content: space-between;
            width: 860px;
            height: 180px;

            .bannerContentMiddleBottomItem {
                cursor: pointer;
                width: 420px;
                height: 180px;

                .name {
                    padding: 22px 0 14px 32px;
                    font-size: 26px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    letter-spacing: 2px;

                }

                .msg {
                    margin-left: 32px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    letter-spacing: 1px;
                    padding-bottom: 5px;
                    border-bottom: 1px solid #FFFFFF
                }
            }
        }

        .bannerContentRight_userInfo {
            padding: 0 20px;
            width: 250px;
            height: 600px;
            background: #FFFFFF;

            .headPortrait {
                margin: 65px auto 16px;
                width: 70px;
                height: 70px;
                border-radius: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }

            .userName {
                text-align: center;
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                letter-spacing: 1px;

            }

            .ant-btn {
                margin: 20px auto;
                width: 200px;
                height: 40px;
                background: #16499A;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }

            .orderNum {
                display: flex;
                justify-content: space-between;
                text-align: center;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;

                .orderNumItem {
                    cursor: pointer;
                }
            }

            .cut-offRule {
                margin: 34px 0;
                width: 210px;
                height: 1px;
                background: #DFDFDF;
            }

            .noticeTitle {
                margin-bottom: 20px;
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #16499A;
            }

            .noticeList {
                .noticeItem {
                    cursor: pointer;
                    line-height: 30px;
                    width: 160px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9A9A9A;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}