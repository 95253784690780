
.ant-pagination{
    margin-top: 30px;
    text-align: center;
}
.root {
    margin: 0 auto;
    width: 1400px;
    background-color: #F6F6F6;

    .goodsList {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .goodItem:hover{
            box-shadow:0px 5px 10px gray;
            transition: .5s;
            transform: scale(1.02);
            cursor: pointer;
        }
        .goodItem {
            margin-top: 20px;
            margin-left: 10px;
            padding: 10px;
            width: 270px;
            height: 365px;
            background: #FFFFFF;
            box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.04);
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .img {
                width: 230px;
                height: 160px;
                background: #FFFFFF;
                margin: 0 auto;
                img {
                    width: 230px;
                    height: 160px;
                }


            }

            .name {
                height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
            }

            .price {
                width: 93px;
                height: 33px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #F16100;
                font-size: 24px;

                span {
                    font-size: 13px;
                }
            }

            .adderss {
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9A9A9A;
                line-height: 20px;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .title {
        width: 1400px;
        height: 330px;
        padding: 20px;
        background-color: #fff;
        padding-bottom: 0px;
        margin-top: 20px;

        .all {
            cursor: pointer;
            width: 66px;
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #DFDFDF;
            text-align: center;
            line-height: 32px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-bottom: 20px;
        }

        .fenlei {
            height: 60px;
            margin: 5px auto;
            border-top: 1px dashed #DFDFDF;
            // border-bottom: 1px dashed #DFDFDF;
            display: flex;
            justify-content: space-between;

            .fenlei_box {
                width: 52px;
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                text-align: center;
                line-height: 60px;
            }
            .list1:hover{
                cursor: pointer;
            }
            .list1 {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                .list2:hover{
                    cursor: pointer;
                }
                .list2:active{
                    color: red;
                }
                .list2 {
                    // margin-left: 30px;
                    padding: 20px 13px;
                    height: 17px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    // line-height: 60px;
                }
            }
        }
    }
}