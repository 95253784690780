.goodsTypeW {
    padding-bottom: 70px;
    background: #F6F6F6;

    .goodsType {
        margin: auto;
        width: 1400px;
        background: #FFFFFF;

        .goodsTypeList {
            width: 1400px;
            background: #F6F6F6;

            .goodsTypeItem {
                display: flex;
                margin-bottom: 20px;
                height: 600px;

                .goodsTypeLeft {
                    padding: 47px 0 0 20px;
                    width: 400px;
                    height: 600px;

                    .title {
                        margin-bottom: 10px;
                        font-size: 32px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                    }

                    .describe {
                        margin-bottom: 20px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }

                    .moreBtn {
                        cursor: pointer;
                        text-align: center;
                        line-height: 30px;
                        width: 100px;
                        height: 30px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }

                .goodsTypeRight {
                    width: 1000px;
                    height: 600px;
                    background: #FFFFFF;
                    .goodsList {
                        display: flex;
                        flex-wrap: wrap;    
                        .goodsItem {
                            cursor: pointer;
                            padding: 20px 20px 15px;
                            width: 250px;
                            height: 300px;
                            background: #FFFFFF;
                            border: 1px solid #DFDFDF;
                            transition: all .2s linear;

                            .goodsImg {
                                width: 210px;
                                height: 160px;
                                margin-bottom: 10px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .goodsName {
                                font-size: 16px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #333333;
                            }

                            .goodsPrice {
                                font-size: 14px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #F16100;

                                .goodsPriceNum {
                                    font-size: 24px;
                                }
                            }

                            .goodsItemBottom {
                                display: flex;
                                justify-content: space-between;
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #9A9A9A;
                            }
                            .goodsItemBottom1 {
                              
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #9A9A9A;
                            }

                            &:hover {
                                box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
                                transform: translate3d(0, -2px, 0);
                            }
                        }
                    }
                }
            }
        }
    }
}