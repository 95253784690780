.personalCenterW {
    background: #F6F6F6;
    padding-bottom: 40px;
    .personalCenter {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        margin: auto;
        width: 1400px;

        .personalCenterLeft {
            padding-top: 20px;
            width: 214px;
            height: 679px;
            background: #FFFFFF;

            .headPortrait {
                margin: 0 auto 14px;
                width: 68px;
                height: 68px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }

            .name {
                margin-bottom: 30px;
                text-align: center;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                letter-spacing: 1px;
            }

            .balance {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                padding: 0 10px;

                .balanceItemNum,
                .balanceItemName {
                    cursor: pointer;
                    text-align: center;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9A9A9A;
                    letter-spacing: 1px;

                }
            }

            .heng {
                margin-bottom: 30px;
                height: 1px;
                background: #DFDFDF;
            }

            .linkList {
                display: flex;
                flex-direction: column;

                .linkItem {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    margin-bottom: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    letter-spacing: 1px;

                    .icon {
                        margin-right: 21px;
                        opacity: 0;
                        width: 1px;
                        height: 12px;
                        background: #16499A;

                    }

                    .active {
                        margin-right: 21px;
                        opacity: 1;
                        width: 1px;
                        height: 12px;
                        background: #16499A;
                    }

                    &.activeLinkItem {
                        color: #16499A;
                    }
                }
            }
        }
    }

}