.personalData {
    width: 1166px;
    height: 679px;
    background: #FFFFFF;

    .personalDataTitle {
        padding-left: 20px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        letter-spacing: 1px;
        border-bottom: 1px solid #DFDFDF;
    }

    .personalDataFrom {
        padding: 20px 35px;

        .personalDataFromItem {
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            .personalDataFromItemName {
                margin-right: 10px;
                width: 76px;
                text-align: end;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9A9A9A;
                letter-spacing: 1px;
            }

            .personalDataFromItemInput {
                input {
                    padding-left: 20px;
                    width: 500px;
                    height: 40px;
                    background: #F6F6F6;
                    border: 1px solid #DFDFDF;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    outline-color: #1890ff;
                    letter-spacing: 1px;
                }
            }

            .personalDataFromItemSex {
                display: flex;
                align-items: center;

                input {
                    margin-right: 10px;
                    width: 15px;
                    height: 15px;
                }

                .sex {
                    margin-right: 30px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    letter-spacing: 1px;
                }
            }

            .touXiangImg {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9A9A9A;
                letter-spacing: 1px;

                img {
                    margin-right: 10px;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                }

                span{
                    cursor: pointer;
                }
            }
        }
        .ant-btn {
            margin-left:300px;
            margin-top: 50px;
            width: 100px;
            height: 35px;
            background: #16499A;
        }
    }
}