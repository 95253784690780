.myFavorite {
    width: 1166px;
    height: 679px;

    .myFavoriteList {
        display: flex;
        flex-wrap: wrap;

        .myFavoriteItem {
            cursor: pointer;
            position: relative;
            padding: 20px;
            width: 290px;
            height: 300px;
            background: #FFFFFF;
            border: 1px solid #DFDFDF;

            &:hover {
                box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
                transform: translate3d(0, -2px, 0);
                -webkit-transition: all 0.5s; 
                -moz-transition: all 0.5s;
                 -o-transition: all 0.5s; 
                 -ms-transition: all 0.5s;
            }
            &:hover .myFavoriteItemDelPic{
                display: block  ;
                -webkit-transition: all 0.5s; 
                -moz-transition: all 0.5s;
                 -o-transition: all 0.5s; 
                 -ms-transition: all 0.5s;
            }

            .myFavoriteItemPic {
                margin-bottom: 10px;
                width: 250px;
                height: 160px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .myFavoriteItemName {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }

            .myFavoriteItemPrice {
                margin-bottom: 10px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #F16100;

                .price {
                    font-size: 24px;
                }
            }

            .myFavoriteItemBottom {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9A9A9A;
            }

            .myFavoriteItemDelPic {
                display: none;
                position: absolute;
                top: 0;
                right: 0;
                text-align: center;
                line-height: 40px;            
                width: 40px;
                height: 40px;
                background: #000000;
                opacity: 0.4;
            }
        }
    }
}