.storeInformation {
    width: 1166px;
    height: 679px;
    background: #FFFFFF;

    .storeInformationTitle {
        padding-left: 20px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        letter-spacing: 1px;
        border-bottom: 1px solid #DFDFDF;
    }

    .storeInformationFrom {
        padding: 20px 33px;

        .storeInformationFromItem {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .storeInformationFromItemName {
                margin-right: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9A9A9A;
                letter-spacing: 1px;
            }

            .storeInformationFromItemUploadName {
                width: 160px;
                margin-right: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9A9A9A;
                letter-spacing: 1px;
            }

            .storeInformationFromItemInput {
                input {
                    padding-left: 20px;
                    width: 500px;
                    height: 40px;
                    outline-color: #1890ff;
                    background: #F6F6F6;
                    border: 1px solid #DFDFDF;
                }
            }
        }

        .ant-btn {
            margin-left: 320px;
            margin-top: 85px;
            width: 100px;
            height: 35px;
            background: #16499A;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            letter-spacing: 1px;
        }
    }
}