.myMessage {
    width: 1166px;
    height: 679px;
    background: #FFFFFF;

    .myMessageTitle {
        padding-left: 20px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        letter-spacing: 1px;
        border-bottom: 1px solid #DFDFDF;
    }
}