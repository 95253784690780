.noticeW {
    background: #FFFFFF;

    .notice {
        margin: auto;
        padding: 30px 0 20px;
        width: 1400px;
        background: #FFFFFF;

        .title {
            margin-bottom: 30px;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }

        .content {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
    }
}