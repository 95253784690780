.myOrder {
    width: 1166px;
    height: 679px;

    // background: #FFFFFF;
    .myOrderTop {
        padding: 0 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .ant-space {
            display: flex;
            flex-direction: row;
            align-items: center;

            .ant-space-item {
                &>span {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }

                .ant-picker {
                    width: 250px;
                    height: 35px;
                    background: #FFFFFF;
                }
            }
        }

        .orderNumber {
            .name {
                margin-right: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }

            input {
                width: 250px;
                height: 35px;
                background: #FFFFFF;
            }
        }
    }

    .myOrderContent {
        width: 1166px;
        height: 530px;
        background: #FFFFFF;

        .myOrderContentTop {
            display: flex;
            justify-content: space-between;
            padding: 0 20px 0 37px;
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #DFDFDF;

            .myOrderContentTop_left {
                .myOrderTitleType {
                    display: flex;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    letter-spacing: 1px;

                    .myOrderTitleTypeItem {
                        cursor: pointer;
                        margin-right: 66px;
                    }

                    .active {
                        color: #16499A;
                        border-bottom: 1px solid #16499A;
                    }
                }
            }

            .myOrderContentTop_right {
                .del {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9A9A9A;
                }
            }
        }
    }

    .myOrderContentTable {
        .table {
            border-collapse: collapse;
            width: 1126px;
            margin: auto;

            .tableThead {
                .theadTr {
                    height: 60px;

                    th {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #9A9A9A;
                        line-height: 20px;
                        letter-spacing: 1px;
                    }
                }
            }

            .tableTbody {
                width: 1126px;

                .tbodyTr {
                    cursor: pointer;
                    border-collapse: collapse;
                    margin-bottom: 10px;
                    width: 1126px;
                    height: 70px;
                    background: #F6F6F6;
                    border: 1px solid #DFDFDF;

                    td {
                        text-align: center;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;

                        img {
                            width: 50px;
                            height: 50px;
                        }
                    }

                    &:hover {
                        height: 70px;
                        background: rgba(22, 73, 154, 0.05);
                        border: 1px solid #16499A;
                    }
                }

                .gap {
                    height: 10px;
                }
            }
        }
    }

    .myOrderTopFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        height: 89px;
        background: #FFFFFF;
        border-top: 1px solid #DFDFDF;

        .checkAll {
            display: flex;
            input {
                margin-right: 10px;
                width: 20px;
                height: 20px;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
            }

            span {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9A9A9A;
            }
        }
    }

}