.shoppingCartsW {
    background: #FFFFFF;

    .shoppingCarts {
        margin: 20px auto;
        width: 1400px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;

        .shoppingCartsTitle {
            padding: 14px 20px;
            height: 50px;
            background: #FFFFFF;
            border-bottom: 1px solid #DFDFDF;

            .title {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }
        }

        .shoppingCartsContent {
            padding: 20px;

            .shopCartTable {
                .shopCartTable_thead {
                    padding: 0 20px;
                    height: 40px;
                    line-height: 40px;
                    border: 1px solid #DFDFDF;

                    .shopCartTable_thead_tr {
                        display: flex;
                        justify-content: space-between;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #9A9A9A;

                        .checkbox {
                            display: flex;
                            align-items: center;

                            input {
                                margin-right: 10px;
                                width: 20px;
                                height: 20px;
                                background: #FFFFFF;
                                border: 1px solid #DFDFDF;
                            }
                        }
                    }
                }

                .shopCartTable_tbody {
                    background: #FFFFFF;

                    .shopCartTable_tbody_tr {
                        padding: 0 20px;
                        margin-bottom: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        height: 110px;
                        background: #F6F6F6;
                        border: 1px solid #DFDFDF;
                        color: #333333;

                        &:hover {
                            background: rgba(22, 73, 154, 0.05);
                            border: 1px solid #16499A;
                        }

                        .tbody_checkbox {
                            display: flex;
                            align-items: center;
                            margin-right: 120px;

                            input {
                                width: 20px;
                                height: 20px;
                                margin-right: 30px;
                            }

                            img {
                                width: 70px;
                                height: 70px;
                            }
                        }

                        .td_name {
                            margin-right: 120px;
                            width: 200px;
                            text-align: center;
                        }

                        .td_spec {
                            margin-right: 160px;
                            width: 100px;
                            text-align: center;
                        }

                        .td_num {
                            margin-right: 160px;
                            width: 110px;
                            text-align: center;

                            input {
                                margin: 0 10px;
                                width: 50px;
                                height: 30px;
                                background: #FFFFFF;
                                border: 1px solid #DFDFDF;
                            }
                        }

                        .td_price {

                            margin-right: 180px;
                            width: 100px;
                            text-align: center;
                        }

                        .td_del {
                            cursor: pointer;
                            width: 50px;
                            text-align: center;
                        }
                    }

                }
            }
        }

        .shoppingCartsFooter {
            display: flex;
            justify-content: space-between;
            padding-left: 20px;
            height: 60px;
            background: #FFFFFF;
            border: 1px solid #DFDFDF;

            .shoppingCartsFooter_left {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9A9A9A;

                .checkboxAll {
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                    background: #FFFFFF;
                    border: 1px solid #DFDFDF;
                }
                span{
                    cursor: pointer;
                    margin-right: 30px;
                }
            }

            .shoppingCartsFooter_right {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9A9A9A;
                .span{
                    margin-right: 30px;
                }
                .num {
                    color: #F16100;
                    font-size: 26px;
                }
                .seleJIesuan{
                    cursor: pointer;
                    width: 190px;
                    height: 60px;
                    background: red;
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 60px;
                    letter-spacing: 1px;
                }
                .jiesuan {
                    cursor: pointer;
                    width: 190px;
                    height: 60px;
                    background: #9A9A9A;
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 60px;
                    letter-spacing: 1px;
                }
            }
        }
    }
}