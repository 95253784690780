.orderDetailsW {
    background: #F6F6F6;
    padding: 40px 0;

    .orderDetails {
        margin: auto;
        width: 1400px;

        .orderDetailsTop {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .orderInfo {
                width: 700px;
                height: 225px;
                background: #FFFFFF;

                .orderInfoTitle {
                    padding-left: 20px;
                    line-height: 46px;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    height: 45px;
                    border-bottom: 1px solid #DFDFDF;
                    background: #FFFFFF;
                }

                .orderInfoContent {
                    padding: 20px;
                    width: 700px;
                    height: 180px;
                    background: #FFFFFF;

                    .orderInfoContentItem {
                        margin-bottom: 16px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }

            .orderType {
                padding: 20px;
                width: 690px;
                height: 225px;
                background: #FFFFFF;

                .zhuangtai {
                    width: 144px;
                    height: 25px;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 25px;
                }

                .wuliu {
                    margin-top: 10px;
                    padding: 20px;
                    width: 600px;
                    height: 90px;
                    background: #F6F6F6;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9A9A9A;
                    line-height: 20px;
                }

                .footer {
                    margin-top: 10px;
                    width: 68px;
                    height: 30px;
                    background: #FFFFFF;
                    border: 1px solid #16499A;
                    text-align: center;
                    line-height: 30px;
                    color: #16499A;
                }
            }
        }

        .orderDetailsBottom {
            margin: 0 auto;
            padding-bottom: 20px;
            width: 1400px;
            background: #FFFFFF;

            .orderDetailsBottomTitle {
                padding: 0 20px;
                height: 45px;
                line-height: 46px;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                border-bottom: 1px solid #F6F6F6;
            }

            .orderDetailsMsg {
                padding: 0 20px;


                .orderDetailsMsgTable {
                    width: 100%;

                    .orderDetailsMsgTable_thead {

                        .orderDetailsMsgTable_thead_tr {
                            height: 60px;
                            background: #FFFFFF;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #9A9A9A;
                        }
                    }

                    .orderDetailsMsgTable_tbody {
                        .orderDetailsMsgTable_tbody_tr {
                            height: 110px;
                            background: #F6F6F6;
                            border: 1px solid #DFDFDF;

                            td {
                                text-align: center;

                                img {
                                    width: 70px;
                                    height: 70px;
                                }
                            }
                        }
                    }
                }

            }

            .orderDetailsMsgPrice {
                padding: 20px 48px;
                margin: 30px auto 0;
                display: flex;
                flex-direction: column;
                align-content: flex-end;
                flex-wrap: wrap;
                width: 1360px;
                height: 184px;
                background: rgba(22, 73, 154, 0.05);
                border: 1px solid #16499A;

                .orderDetailsMsgPriceItem {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;

                    .price {
                        font-size: 24px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }

                    .num {
                        font-size: 34px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #F16100;
                    }

                }
            }
        }
    }
}