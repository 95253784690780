.homeSpecial {
    background: #F6F6F6;

    .homeSpecialBanner {
        height: 350px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .homeSpecialGoodsList {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0 40px;
        margin: auto;
        width: 1400px;

        .LinkJump {
            margin: 0 12px 12px 0;

            &:nth-child(5n) {
                margin-right: 0;
            }
        }

        .homeSpecialGoodsItem {
            cursor: pointer;
            padding: 20px;
            width: 270px;
            height: 305px;
            background: #FFFFFF;
            box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.04);

            &:hover {
                box-shadow: 0 15px 30px rgba(0, 0, 0, .1);
                transform: translate3d(0, -2px, 0);
            }

            .homeSpecialGoodsItemImg {
                margin-bottom: 10px;
                width: 230px;
                height: 160px;
                background: #FFFFFF;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .homeSpecialGoodsName {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }

            .homeSpecialGoodsItemPrice {
                margin-bottom: 10px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #F16100;

                .discountedPriceNum {
                    margin-right: 10px;
                    font-size: 24px;
                }

                .discountPriceNum {
                    text-decoration: line-through;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9A9A9A;
                }
            }

            .homeSpecialGoodsItemBottom {
                display: flex;
                justify-content: space-between;

                .spec {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9A9A9A;
                }

                .describe {
                    width: 38px;
                    height: 18px;
                    border-radius: 4px;
                    border: 1px solid #AE640B;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #AE640B;
                    line-height: 18px;
                    text-align: center;
                }
            }
        }
    }

    .ant-pagination {
        text-align: center;
        margin-bottom: 40px;
        padding-bottom: 40px;
    }
}