.headerContentW {
    height: 150px;
    background: #FFFFFF;

    .headerContent {
        display: flex;
        margin: auto;
        // overflow: hidden;
        width: 1400px;
        height: 150px;
        background: #FFFFFF;

        .headerContentLogo {
            margin: 30px 132px 30px 80px;
            width: 66px;
            height: 90px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .headerContentRight {
            .headerContentRight_top {
                display: flex;

                .searchInput {
                    .ant-space {
                        margin-top: 30px;
                        margin-right: 122px;

                        .ant-space-item {
                            .ant-input-group-wrapper {
                                .ant-input-wrapper {
                                    .ant-input-affix-wrapper {
                                        height: 50px;
                                    }

                                    .ant-input-group-addon {
                                        .ant-btn {
                                            width: 60px;
                                            height: 50px;

                                            .anticon-search {
                                                svg {
                                                    width: 21px;
                                                    height: 21px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .myShoppingCart {
                    margin-top: 30px;

                    .ant-btn {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 180px;
                        height: 50px;

                        .shopCartSpan {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #4A4A4A;
                        }
                    }
                }
            }

            .headerContentRight_bottom {
                width: 900px;

                .linkList {
                    margin-top: 28px;
                    display: flex;
                    justify-content: space-between;

                    .linkItem {
                        cursor: pointer;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        letter-spacing: 1px;

                        &.activeLinkItem {
                            color: #16499A;
                        }
                    }
                }
            }
        }
    }
}