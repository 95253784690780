.father {
    width: 1166px;
    height: 679px;
    padding: 20px;
    background: #FFFFFF;
    .title {
        width: 98px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4A4A4A;
        line-height: 25px;
        letter-spacing: 1px;
    }
}



.zongFen {
    margin-top: 23px;
    height: 37px;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #D8D8D8;

    div:nth-child(1) {
        margin-left: 0px;
    }

    div {
        width: 130px;
        height: 37px;
        line-height: 37px;
        text-align: center;
        cursor: pointer;
        border-radius: 7px 7px 0px 0px;
        border: 1px solid #D8D8D8;
        margin-left: 10px;
    }

}

.youhuiquan {
    height: 200px;
    margin-top: 20px;
    #box3{
        position: absolute;
        top: 0px;
        width: 60px;
        height: 60px;
        right: 10px;
        // background-color: pink;
        background-image: url('../../../assets//image/yishiyong.png');
        background-size:100% 100%;
    }
    #shixiao{
        position: relative;
        width: 348px;
        height: 109px;
        background-size: 100%;
        background-image: url('../../../assets/image/weishiyong.png') ;
    }
    #guoqi{
        position: relative;
        width: 348px;
        height: 109px;
        background-size: 100%;
        background-image: url('../../../assets/image/weishiyong.png') ;
    }
    .youhuiquan_box {
        position: relative;
        width: 348px;
        height: 109px;
        background-size: 100%;
        background-image: url('../../../assets/image/youxiao.png');
        .youhuiquan_box1{
            width: 348px;
            height: 80px;
            display: flex;
            justify-content: space-between;
            .box1{
                height: 80px;
                width: 90px;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #FFFFFF;
                .price{
                    font-size: 24px;
                }
                .lidu{
                    margin-left: 10px;
                }
            }
            .box2{
                position: relative;
                padding: 15px;
                flex: 1 ;
                height: 80px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #FFFFFF;
            }
        }
        .guize {
            margin-left: 10px;
            position: absolute;
            bottom: 0px;
            width: 143px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4A4A4A;
            line-height: 17px;
        }
    }
}