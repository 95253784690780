.headerTopW {
    height: 40px;
    background: #F6F6F6;

    .headerTop {
        display: flex;
        justify-content: space-between;
        margin: auto;
        width: 1400px;
        height: 40px;
        background: #F6F6F6;

        .headerTitle {
            cursor: pointer;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 40px;
            letter-spacing: 1px;
        }

        .headerNav {
            width: 300px;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 40px;

            .regLogin,
            .order,
            .phone {
                cursor: pointer;
                flex: 1;
            }

            .regLogin {
                background: url(../../assets/image/header_login.png) no-repeat;
                background-size: 16px;
                background-size: 16px;
                background-position: -1px;
                text-align: center;
            }

            .order {
                background: url(../../assets/image/header_order.png) no-repeat;
                background-size: 16px;
                background-size: 16px;
                background-position: -1px;
                text-align: center;
            }

            .phone {
                background: url(../../assets/image/header_phone.png) no-repeat;
                background-size: 16px;
                background-size: 16px;
                background-position: 7px;
                text-align: center;
            }
        }
    }
}

.loginModal {
    width: 741px !important;

    .ant-modal-content {
        width: 741px;
        height: 498px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 20px;

        .ant-modal-header {
            border-bottom: none;

            .ant-modal-title {
                text-align: center;

                img {
                    width: 215px;
                    height: 96px;
                }
            }
        }

        .ant-modal-body {
            .ant-form {
                .ant-form-item {
                    .ant-form-item-control {
                        .ant-form-item-control-input {
                            .ant-form-item-control-input-content {
                                text-align: center;

                                .ant-input-affix-wrapper {
                                    width: 497px;
                                    height: 65px;
                                    background: #FFFFFF;
                                    border: 1px solid #C8C8C8;
                                    opacity: 1;
                                    border-radius: 8px;

                                    .ant-input-prefix {
                                        .anticon {
                                            font-size: 22px;
                                            color: #B7B7B7;
                                        }
                                    }

                                    .ant-input {
                                        font-size: 22px;
                                    }
                                }

                                .ant-btn {
                                    width: 497px;
                                    height: 63px;
                                    background: #16499A;
                                    opacity: 1;
                                    border-radius: 8px;
                                    font-size: 20px;
                                    font-family: PingFang SC;
                                    font-weight: bold;
                                    color: #FFFFFF;
                                    opacity: 1;
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}