.integral {
    width: 1166px;
    height: 769px;
    background: #FFFFFF;

    .integralTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        height: 77px;
        border-bottom: 1px solid #DFDFDF;

        .integralTop_left {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;

            .price {
                color: #F16100;
                font-size: 26px;
            }
        }

        .integralTop_right {
            .ant-btn {
                width: 136px;
                height: 42px;
                background: #16499A;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                letter-spacing: 1px;
            }
        }
    }

    .integralContent {
        padding: 20px;

        .integralContentTitle {
            padding-bottom: 20px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4A4A4A;
            letter-spacing: 1px;
        }

        .balanceTable {
            .table {
                width: 100%;

                .balanceTable_thead {
                    .balanceTable_thead_tr {
                        height: 42px;
                        background: #F6F6F6;
                        border: 1px solid #DFDFDF;
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #4A4A4A;
                        letter-spacing: 1px;
                    }
                }

                .balanceTable_tbody {
                    .balanceTable_tbody_tr {
                        height: 62px;
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #4A4A4A;
                        /* line-height: 20px; */
                        letter-spacing: 1px;
                        border-bottom: 1px solid #DFDFDF;

                        td {
                            text-align: center;
                        }
                    }
                }
            }
        }

        .ant-pagination {
            text-align: right;
            margin-top: 20px;
        }
    }
}

.isPayModal {
    width: 1009px !important;

    .ant-modal-content {
        width: 1009px;
        height: 548px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 39px;

        .ant-modal-header {
            border: none;
            border-radius: 39px;

            .ant-modal-title {
                text-align: center;
                font-size: 28px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 38px;
                color: #202020;
                letter-spacing: 8px;
                opacity: 1;
            }
        }

        .ant-modal-body {
            padding-left: 74px;
            padding-top: 54px;

            .payPrice {
                .payPriceSpan {
                    margin-right: 24px;
                    font-size: 22px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    line-height: 38px;
                    color: #363636;
                    letter-spacing: 1px;
                    opacity: 1;
                }

                .payPriceInput {
                    text-align: center;
                    width: 275px;
                    height: 59px;
                    background: #FFFFFF;
                    border: 1px solid #7F7F7F;
                    opacity: 1;
                    outline-color: #1890ff;
                    border-radius: 11px;
                }
            }

            .paymentMethod {
                margin-top: 20px;

                .paymentMethodDiv {
                    display: flex;
                    align-items: center;

                    .paymentMethodSpan {
                        margin-right: 42px;
                        font-size: 22px;
                        font-family: PingFang SC;
                        font-weight: bold;
                        line-height: 38px;
                        color: #363636;
                        letter-spacing: 1px;
                        opacity: 1;
                    }

                    .ant-radio-group {
                        display: flex;

                        .ant-radio-wrapper {
                            display: flex;
                            flex-direction: column-reverse;
                            align-items: center;
                        }
                    }
                }

            }
        }

        .ant-modal-footer {
            border: none;
            margin-top: 77px;
            text-align: center;
            .ant-btn {
                width: 321px;
                height: 63px;
                background: #0097D0;
                opacity: 1;
                border-radius: 8px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
            }
        }
    }
}
