.productDetailsW {
    background: #F6F6F6;

    .productDetails {
        padding: 20px 0;
        margin: auto;
        width: 1400px;

        .productDetailsTop {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;

            .productInfo {
                display: flex;
                padding: 20px;
                width: 1110px;
                height: 585px;
                background: #FFFFFF;

                .productInfoLeft {
                    .productInfoImgList {
                        margin: 20px 0;
                        display: flex;
                        width: 315px;

                        .productInfoImgItem {
                            cursor: pointer;
                            margin-right: 10px;
                            width: 55px;
                            height: 55px;

                            &:last-child {
                                margin-right: 0
                            }

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .shoucang {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #9A9A9A;

                        img {
                            margin-right: 10px;
                        }
                    }
                }

                .productInfoRight {
                    margin-left: 20px;
                    padding-top: 24px;

                    .productInfoName {
                        margin-bottom: 20px;
                        font-size: 24px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                    }

                    .productInfoContent {
                        margin-bottom: 10px;
                        padding: 0 10px;
                        width: 335px;
                        height: 121px;
                        background: #FFFFFF;
                        border: 1px solid #DD5700;

                        .productInfoContentItem {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            height: 40px;
                            border-bottom: 1px dashed #FFB65E;

                            &:last-child {
                                border-bottom: none;
                            }

                            .item {
                                .name {
                                    font-size: 14px;
                                    font-family: PingFangSC-Semibold, PingFang SC;
                                    font-weight: 600;
                                    color: #333333;
                                }

                                .value {
                                    font-size: 14px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 600;
                                    color: #5C5C5C;
                                }
                            }

                        }
                    }

                    .productInfoPrice {
                        margin-bottom: 20px;
                        font-size: 24px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #F16100;
                    }

                    .productInfoTips {
                        margin-bottom: 24px;

                        .name {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #9A9A9A;
                        }

                        .tips {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #4A4A4A;
                        }

                        .price {
                            color: #F16100;
                        }
                    }

                    .productInfoNum {
                        display: flex;
                        align-items: center;
                        margin-bottom: 30px;

                        .name {
                            margin-right: 10px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #9A9A9A;
                        }

                        .inputNum {
                            margin-right: 10px;
                            display: flex;
                            height: 35px;
                            background: #FFFFFF;

                            .sub,
                            .add {
                                cursor: pointer;
                                display: inline-block;
                                width: 35px;
                                height: 35px;
                                font-size: 28px;
                                text-align: center;
                                line-height: 32px;
                                background: #DFDFDF;
                            }

                            .input {
                                display: inline-block;
                                text-align: center;
                                border: none;
                                width: 80px;
                                height: 35px;
                                border: 1px solid #DFDFDF;
                            }
                        }

                        .unit {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }
                    }

                    .maiBtn {
                        display: flex;

                        .addCart {
                            cursor: pointer;
                            margin-right: 20px;
                            text-align: center;
                            line-height: 50px;
                            width: 180px;
                            height: 50px;
                            background: rgba(22, 73, 154, 0.05);
                            border: 1px solid #16499A;
                            font-size: 20px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #16499A;
                        }

                        .buy {
                            cursor: pointer;
                            text-align: center;
                            line-height: 50px;
                            width: 180px;
                            height: 50px;
                            background: #16499A;
                            border: 1px solid #16499A;
                            font-size: 20px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                        }

                    }
                }
            }

            .buyerLook {
                padding: 20px 25px;
                width: 280px;
                height: 585px;
                background: #FFFFFF;

                .buyerLookTitle {
                    margin-bottom: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .heng {
                        width: 60px;
                        height: 1px;
                        background: #DFDFDF;
                    }

                    .title {
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333
                    }
                }

                #img {
                    width: 60px;
                    height: 60px;

                    img {
                        width: 160px;
                        height: 100px;
                    }
                }
                
              
                .buyerLookGoodsList {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .buyerLookGoodsItem {
                        cursor: pointer;
                        margin-bottom: 10px;
                        height: 160px;

                        .buyerLookGoodsItemImg {
                            position: relative;
                            width: 130px;
                            height: 130px;

                            .price {
                                position: absolute;
                                bottom: 0;
                                text-align: center;
                                line-height: 30px;
                                width: 130px;
                                height: 30px;
                                background: #FFFFFF;
                                opacity: 0.8;
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #333333
                            }
                        }

                        .name {
                            margin-top: 10px;
                            text-align: center;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }
                    }
                }
            }
        }

        .productDetailsBottom {
            display: flex;
            justify-content: space-between;

            .goodsRecommend {
                padding: 20px 25px;
                width: 280px;
                height: 1100px;
                background: #FFFFFF;

                .goodsRecommendTitle {
                    margin-bottom: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .heng {
                        width: 60px;
                        height: 1px;
                        background: #DFDFDF;
                    }

                    .title {
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333
                    }
                }

                .goodsRecommendGoodsList {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .goodsRecommendGoodsItem {
                        cursor: pointer;
                        margin-bottom: 10px;
                        height: 160px;

                        .goodsRecommendGoodsItemImg {
                            position: relative;
                            width: 130px;
                            height: 130px;

                            .price {
                                position: absolute;
                                bottom: 0;
                                text-align: center;
                                line-height: 30px;
                                width: 130px;
                                height: 30px;
                                background: #FFFFFF;
                                opacity: 0.8;
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #333333
                            }
                        }

                        .name {
                            margin-top: 10px;
                            text-align: center;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }
                    }
                }
            }

            .productDetailInfo {
                width: 1100px;
                background: #FFFFFF;

                .productDetailInfoTitle {
                    height: 50px;
                    border-bottom: 1px solid #DFDFDF;

                    .productDetailInfoTitleName {
                        width: 150px;
                        text-align: center;
                        line-height: 50px;
                        border-bottom: 1px solid #16499A;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #16499A;
                    }
                }

                .productDetailInfoContent {
                    padding: 20px 30px 40px 30px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}