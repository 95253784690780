.biaodan {
    margin-top: 20px;
  
    .citydiv {
      margin-bottom: 24px;
      margin-left: 24px;
  
      .city {
        width: 439px;
      }
    }
  
    .xiangxi {
      margin-left: 24px;
  
      span {
        // display: inline-block;
        float: left;
      }
  
      textarea {
        resize: none;
        border: 1px solid #d9d9d9;
        padding: 5px 12px;
      }
    }
  
    .ant-form {
      .ant-form-item {
        .ant-form-item-control {
          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
  .mydizhi {
    min-height: 938px;
    border: 1px solid #E9E9E9;
    padding: 0 22px;
    padding-bottom: 42px;
    background: #FFFFFF;
    .biaoti {
      padding: 20px;
      margin-bottom: 16px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4A4A4A;
      line-height: 22px;
      letter-spacing: 1px;
      border-bottom: 1px solid #EEEEEE;
    }
  
    .shouhuobiaoti {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #D3040F;
      line-height: 22px;
      letter-spacing: 1px;
    }
  }
  .biaoge {
    .tishi {
      padding-left: 20px;
      margin-bottom: 20px;
      width: 100%;
      height: 40px;
      line-height: 40px;
      background: #FFDADB;
  
      span:nth-child(1) {
        text-align: center;
        line-height: 16px;
        width: 16px;
        height: 16px;
        display: inline-block;
        border-radius: 50%;
        background: #BD2C21;
        color: #FFF;
      }
  
      span:nth-child(2) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
        margin-left: 4px;
      }
    }
  
    .shouhuobiaoge {
      min-height: 372px;
      border: 1px solid #DADBDD;
  
      .listTable {
        width: 100%;
  
        .listThead {
          width: 1400px;
          height: 56px;
          background: #FAFAFA;
          border: 1px solid #EEEEEE;
  
          .listTr {
            table-layout: fixed;
            height: 56px;
            line-height: 56px;
            border-top: 1px solid #EEEEEE;
            border-left: 1px solid #EEEEEE;
            border-right: 1px solid #EEEEEE;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #505050;
            text-align: center;
  
            th {
              border-right: 1px solid #DADBDD;
              ;
            }
          }
        }
  
        .listTbody {
          width: 1400px;
          height: 60px;
          background: #FFFFFF;
          border: 1px solid #F7F7F7;
  
          .TbodyTr:hover {
            background: rgba(189, 44, 33, 0.05);
            color: #BD2C21;
          }
  
          .TbodyTr {
            table-layout: fixed;
            height: 60px;
            // line-height: 60px;
            border-top: 1px solid #EEEEEE;
            border-left: 1px solid #EEEEEE;
            border-right: 1px solid #EEEEEE;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4A4A4A;
            line-height: 20px;
  
            td {
              text-align: center;
              border-right: 1px solid #DADBDD;
  
              &:nth-child(2) {
                padding: 0 44px;
              }
            }
  
            .moren {
              width: 80px;
              height: 30px;
              margin: auto;
              text-align: center;
              line-height: 30px;
              background: rgba(211, 4, 15, 0.3);
              border-radius: 4px;
              border: 1px solid #D3040F;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #D3040F;
            }
          }
        }
      }
    }
  }
  
  .xiugaiModal .ant-modal-content{
    width: 800px;
    height: 468px;
    background: #FFFFFF;
    border-radius: 12px;
    .ant-modal-footer{
      border-top: none;
    }
  }