.confirmOrderW {
    background: #F6F6F6;

    .confirmOrder {
        margin: auto;
        width: 1400px;
        border: 1px solid #DFDFDF;

        .confirmOrderTitle {
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            height: 50px;
            background: #FFFFFF;
            border-bottom: 1px solid #DFDFDF;

            .confirmOrderTitle_right {
                cursor: pointer;
            }
        }

        .confirmOrderAddressList {
            padding: 20px;
            background: #FFFFFF;

            .ant-radio-group {
                width: 100%;
            }

            span.ant-radio+* {
                padding-right: 8px;
                padding-left: 8px;
                width: 100%;
            }

            .confirmOrderAddressItem {
                width: 100%;
                display: flex;

                .itemLeft {
                    display: flex;

                    .di {
                        margin: 0 10px 0 18px;
                    }

                    .nei {
                        span {
                            margin-right: 20px;

                            .address {
                                margin-right: 10px;
                            }
                        }
                    }

                    .xiugai {
                        cursor: pointer;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #16499A;
                    }
                }

                .flex1 {
                    flex: 1;
                }

                .itemRight {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }

        .orderInfo {
            padding: 0 20px;
            background: #FFFFFF;

            .orderInfoTitle {
                margin-bottom: 20px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }

            .orderInfoList {
                .orderInfoTable {
                    width: 100%;
                    border: 1px solid #DFDFDF;

                    .orderInfoTable_thead {

                        .orderInfoTable_thead_tr {
                            height: 40px;
                            background: #FFFFFF;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #9A9A9A;
                        }
                    }

                    .orderInfoTable_tbody {
                        .orderInfoTable_tbody_tr {
                            height: 110px;
                            background: #F6F6F6;
                            border-top: 1px solid #DFDFDF;

                            td {
                                text-align: center;

                                img {
                                    width: 70px;
                                    height: 70px;
                                }
                            }
                        }
                    }
                }

                .orderA {
                    display: flex;
                    justify-content: space-between;
                    padding: 20px 0;

                    .orderBeiZhu {
                        // margin-right: 360px;
                        display: flex;

                        .beiZhu {
                            margin-right: 10px;
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }

                        textarea {
                            padding: 10px 20px;
                            resize: none;
                            width: 450px;
                            height: 110px;
                            background: #FFFFFF;
                            border: 1px solid #DFDFDF;
                        }
                    }

                    .orderMoney {
                        width: 450px;

                        .orderMoneyItem {
                            cursor: pointer;
                            display: flex;
                            justify-content: space-between;

                            .name {
                                display: inline-block;
                                width: 100px;
                                text-align: right;
                                font-size: 16px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #333333;
                            }

                            .value {
                                font-size: 16px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #9A9A9A;
                            }

                            .price {
                                font-size: 16px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #F16100;
                            }
                        }
                    }
                }

                .modeOfPayment {
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    height: 60px;
                    background: #F3F6FA;

                    .modeOfPaymentText {
                        margin-right: 50px;
                        font-size: 16px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                    }
                }

                .delivery {
                    padding: 20px 40px;
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    height: 150px;
                    background: rgba(22, 73, 154, 0.05);
                    border: 1px solid #16499A;

                    .deliveryPayment {
                        font-size: 24px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;

                        .price {
                            font-size: 34px;
                            color: #F16100;
                        }
                    }

                    .deliveryAddress {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }

                    .deliveryRen {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                }


                .orderBottom {
                    padding-bottom: 40px;
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .backCart {
                        cursor: pointer;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #16499A;
                    }

                    .queRenOrderBtn {
                        margin-left: 20px;
                        .ant-btn {
                            width: 200px;
                            height: 60px;
                            background: #16499A;
                            font-size: 24px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
}